<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h2 class="text-center">{{ $t("inicio-sesion.inicio-sesion") }}</h2>
          </div>
          <div class="card-body">
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="username" class="form-label">{{ $t("inicio-sesion.nombre-usuario") }}:</label>
                <input type="text" id="username" v-model="username" class="form-control"
                  :placeholder="$t('inicio-sesion.nombre-usuario')" required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">{{ $t("inicio-sesion.contrasena") }}:</label>
                <input type="password" id="password" v-model="password" class="form-control"
                  :placeholder="$t('inicio-sesion.contrasena')" required>
              </div>
              <div class="d-grid gap-2">
                <button type="submit" class="btn btn-success">{{ $t("inicio-sesion.boton-iniciar-sesion") }}</button>
              </div>
            </form>
            <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: null
    };
  },
  methods: {
    login() {
      this.$store.dispatch('loginUser', {
        username: this.username,
        password: this.password,
      }).catch((error) => {
        return this.errorMessage = error.response.data.message;
      });
    },
  }
};
</script>
  
<style>
.btn-success {
  background-color: #41B883;
  border-color: #41B883;
}

.card {
  margin-top: 20px;
}
</style>
  