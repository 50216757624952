<template>
  <div>
    <h2>Registro de Usuario</h2>
    <form @submit.prevent="registro">
      <label for="nombre">Nombre:</label>
      <input type="text" id="nombre" v-model="nombre" required>

      <label for="apellido">Apellido:</label>
      <input type="text" id="apellido" v-model="apellido" required>

      <label for="email">Email:</label>
      <input type="email" id="email" v-model="email" required>

      <label for="password">Contraseña:</label>
      <input type="password" id="password" v-model="password" required>

      <button type="submit">Registrarse</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegistroUsuario',
  data() {
    return {
      nombre: '',
      apellido: '',
      email: '',
      password: ''
    };
  },
  methods: {
    registro() {
      axios.post('http://127.0.0.1:8000/usuarios/registro/', {
        nombre: this.nombre,
        apellido: this.apellido,
        email: this.email,
        password: this.password
      })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
}
</script>

<style>
/* Estilos */
</style>
