<template>
  <div>
      <nav class="navbar navbar-expand-lg navbar-dark bg-vue-green">
        <div class="container">
          <router-link to="/" class="navbar-brand">{{ $t('main-dashboard.titulo-app') }}</router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/descarga-declaraciones" class="nav-link">{{ $t('main-dashboard.descarga-declaraciones') }}</router-link>
              </li>
              <li class="nav-item">
                <button @click="logout" class="nav-link">{{ $t('main-dashboard.cerrar-sesion') }}</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logoutUser')
        .then(() => {
          this.$router.push('/');
        })
        .catch((error) => {
          console.error(this.$t('main-dashboard.error-cerrar-sesion'), error);
        });
    },
  },
};
</script>

<style scoped>
.dashboard {
  display: flex;
}

.sidebar {
  width: 200px;
}

.main-content {
  flex: 1;
  padding: 20px;
}
.bg-vue-green {
  background-color: #41B883;
}
</style>
