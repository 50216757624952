<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" v-if="!$store.state.isAuthenticated"> -->
    <div v-if="!$store.state.isAuthenticated">
      <registro v-show="false"></registro>
      <inicio-sesion></inicio-sesion>
    </div>
    <div v-else>
      <main-dashboard></main-dashboard>
    </div>
  </div>
</template>

<script>
import Registro from './components/RegistroUsuario.vue';
import InicioSesion from './components/InicioSesion.vue';
import MainDashboard from '@/components/MainDashboard';

export default {
  name: 'App',
  components: {
    Registro,
    InicioSesion,
    MainDashboard
  },
  data() {
    return {
      isLoggedIn: false
    };
  }
}
</script>

<style>
/* Estilos */
</style>
