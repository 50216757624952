import { createRouter, createWebHistory } from 'vue-router';
import DescargaDeDeclaraciones from './components/DescargaDeclaraciones.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/descarga-declaraciones',  
      name: 'DescargaDeDeclaraciones',
      component: DescargaDeDeclaraciones,
    },
  ],
});

export default router;
