import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state: {
        isAuthenticated: false,
        user: null,
    },
    mutations: {
        login(state, user) {
            state.isAuthenticated = true;
            state.user = user;
        },
        logout(state) {
            state.isAuthenticated = false;
            state.user = null;
        },
    },
    actions: {
        async loginUser({ commit }, userData) {
            try {
                const isAuthenticated = await axios.post('https://back.biartechnology.com/usuarios/login/', userData);
                if (isAuthenticated) {
                    const user = {
                        username: userData.username,
                    };
                    commit('login', user);
                } else {
                    throw new Error('Credenciales incorrectas');
                }
            } catch (error) {
                console.error('Error en el inicio de sesión:', error);
                throw error;
            }
        },
        logoutUser({ commit }) {
            commit('logout');
        },
    },
});
